import React from 'react';
import { Router } from '@reach/router';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

const VerifyQr = () => {
  const { loggedIn } = useSelector((state) => state);
  const currentURL = window.location.href;
  if (loggedIn) {
    if (currentURL.includes('when-time-began')) {
      navigate('/when-time-began');
    } else if (currentURL.includes('rise-of-the-prophets')) {
      navigate('/rise-of-the-prophets');
    } else if (currentURL.includes('prophets-priests-and-kings')) {
      navigate('/prophets-priests-and-kings');
    } else if (currentURL.includes('the-miracle-man')) {
      navigate('/the-miracle-man');
    } else if (currentURL.includes('savior-of-the-world')) {
      navigate('/savior-of-the-world');
    } else if (currentURL.includes('way-of-the-master')) {
      navigate('/way-of-the-master');
    } else {
      navigate('/the-greatest-battle');
    }
  } else {
    localStorage.setItem('qrUrl', currentURL);
    navigate('/login');
  }

  return (
    <>
      <Router basepath="/verify-qr"></Router>
    </>
  );
};
export default VerifyQr;
